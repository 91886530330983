import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import GoogleMap from './GoogleMap';

// images

const SERVICE_MAP = {
  '': '我想要得到的服務?',
  剛找好店面需要設計以及裝潢: '剛找好店面需要設計以及裝潢',
  我有設計圖只需要工程: '我有設計圖只需要工程',
  '我想創立品牌，也有想法了(從創立品牌到店面裝潢)':
    '我想創立品牌，也有想法了(從創立品牌到店面裝潢)',
  '已經有經營其他門市，想開新開店面(或是二代店)': '已經有經營其他門市，想開新開店面(或是二代店)',
};

const NEEDS_MAP = {
  '': '目前需求是?',
  我想要了解空間設計: '我想要了解空間設計',
  我想要了解工程統包: '我想要了解工程統包',
  我想要了解關於品牌設計: '我想要了解關於品牌設計',
  '我以上都想要了解，請盡快與我聯繫': '我以上都想要了解，請盡快與我聯繫',
};

const TIME_MAP = {
  '': '方便接電話的時間?',
  '平日9:00-12:00': '平日9:00-12:00',
  '平日12:00-6:00': '平日12:00-6:00',
  其他時間: '其他時間',
};

const LOCATION_MAP = {
  '': '您計畫地點為?',
  宜蘭: '宜蘭',
  基隆: '基隆',
  新北: '新北',
  臺北: '臺北',
  桃園: '桃園',
  新竹: '新竹',
  苗栗: '苗栗',
  臺中: '臺中',
  彰化: '彰化',
  雲林: '雲林',
  嘉義: '嘉義',
  台南: '台南',
  高雄: '高雄',
  屏東: '屏東',
  臺東: '臺東',
  花蓮: '花蓮',
  國外: '國外',
  外島: '外島',
};

function Contact() {
  const [sendingStatus, setSendingStatus] = useState('pending');

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [needs, setNeeds] = useState('');
  const [location, setLocation] = useState('');
  const [time, setTime] = useState('');

  const [formEleClass, setformEleClass] = useState({
    nameClass: '',
    phoneClass: '',
    emailClass: '',
  });

  function onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setName(e.target.value);
  }
  function onPhoneChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setPhone(e.target.value);
  }
  function onEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value);
  }
  function onServiceSelectChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    setService(e.target.value);
  }
  function onNeedsSelectChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    setNeeds(e.target.value);
  }
  function onLocationSelectChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    setLocation(e.target.value);
  }
  function onTimeSelectChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    setTime(e.target.value);
  }
  function handleContact(e: React.MouseEvent<HTMLButtonElement>): void {
    if (!name || !phone || !email) {
      setformEleClass({
        nameClass: name ? '' : 'error',
        phoneClass: phone ? '' : 'error',
        emailClass: email ? '' : 'error',
      });
      return;
    }

    function validation(val: any) {
      return !!val;
    }

    const isValid = validation(name) && validation(phone) && validation(email);

    if (!isValid) {
      return;
    }

    const csrf = uuidv4();

    document.cookie = `csrf=${csrf};`;

    setSendingStatus('sending');

    axios
      .post('/.netlify/functions/sendemail', {
        name: name,
        phone: phone,
        email: email,
        service: service,
        needs: needs,
        location: location,
        time: time,
        csrf: csrf,
      })
      .then((res) => {
        setSendingStatus('success');
      })
      .catch(() => {
        setSendingStatus('error');
      });
  }

  return (
    <Fragment>
      <Navbar />
      <div className="contact-page">
        <div className="title">聯絡洽詢｜CONTACT</div>
        <div className="center-block">
          {/* google map */}
          <div className="map">
            <GoogleMap />
          </div>
          {/* contact */}
          <div className="contact">
            <input
              placeholder="姓名 your name"
              type="text"
              maxLength={100}
              value={name}
              onChange={onNameChange}
              className={name ? 'black ' : formEleClass.nameClass}
            />
            <input
              placeholder="電話 phone"
              type="tel"
              maxLength={100}
              value={phone}
              onChange={onPhoneChange}
              className={phone ? 'black' : formEleClass.phoneClass}
            />
            <input
              placeholder="信箱 email"
              maxLength={100}
              type="email"
              value={email}
              onChange={onEmailChange}
              className={email ? 'black' : formEleClass.emailClass}
            />
            <div className="select">
              <select
                name="service"
                id="service"
                onChange={onServiceSelectChange}
                className={service ? 'black' : ''}
              >
                {Object.entries(SERVICE_MAP).map(([key, value]) => (
                  <option value={key}>{value}</option>
                ))}
              </select>
            </div>
            <div className="select">
              <select
                name="needs"
                id="needs"
                onChange={onNeedsSelectChange}
                className={needs ? 'black' : ''}
              >
                {Object.entries(NEEDS_MAP).map(([key, value]) => (
                  <option value={key}>{value}</option>
                ))}
              </select>
            </div>
            <div className="select">
              <select
                name="location"
                id="location"
                onChange={onLocationSelectChange}
                className={location ? 'black' : ''}
              >
                {Object.entries(LOCATION_MAP).map(([key, value]) => (
                  <option value={key}>{value}</option>
                ))}
              </select>
            </div>
            <div className="select">
              <select
                name="time"
                id="time"
                onChange={onTimeSelectChange}
                className={time ? 'black' : ''}
              >
                {Object.entries(TIME_MAP).map(([key, value]) => (
                  <option value={key}>{value}</option>
                ))}
              </select>
            </div>
            <button onClick={handleContact}>
              {sendingStatus === 'sending'
                ? '寄出中'
                : sendingStatus === 'error'
                ? '寄出失敗'
                : sendingStatus === 'success'
                ? '寄出成功'
                : '傳送'}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Contact;
