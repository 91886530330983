import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface CarouselHeroI {
  carouselImages: Array<string>;
  showThumbs: boolean;
}

function CarouselHero({ carouselImages, showThumbs }: CarouselHeroI) {
  return (
    <div className="Carousel-component">
      <Carousel showThumbs={showThumbs} infiniteLoop={true} showStatus={false} autoPlay={true}>
        {carouselImages.map((url: string, idx: number) => (
          <div key={idx}>
            <img src={url} alt="" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselHero;
