import { createContext } from 'react';
import Index from '../pages/index/index';
import Contact from '../pages/contact/contact';
import About from '../pages/about/about';
import FAQ from '../pages/faq/index';
import Customers from '../pages/customers/index';
import Services from '../pages/services/Services';
import Cases from '../pages/cases/Cases';
import CaseDetail from '../pages/cases/CaseDetail';

const routeConfig = [
  {
    path: '/case-detail/:page',
    component: 'CaseDetail',
    name: 'Show case detail page',
  },
  {
    path: '/cases/:page',
    component: 'Cases',
    name: 'Show case page',
  },
  {
    path: '/cases',
    component: 'Cases',
    name: 'Show case page',
  },
  {
    path: '/service',
    component: 'Services',
    name: 'service page',
  },
  {
    path: '/user-like-us',
    component: 'Customers',
    name: 'User like us page',
  },
  {
    path: '/faq',
    component: 'FAQ',
    name: 'faq page',
  },
  {
    path: '/about',
    component: 'About',
    name: 'about page',
  },
  {
    path: '/contact',
    component: 'Contact',
    name: 'Contact page',
  },
  {
    path: '/',
    component: 'Index',
    name: 'Index page',
  },
];

// interface ComponentProps extends RouteComponentProps;

export const routesMap: { [key: string]: any } = {
  Index,
  Contact,
  About,
  FAQ,
  Customers,
  Services,
  Cases,
  CaseDetail,
};

export const RoutesContext = createContext(routeConfig);

export default routeConfig;
