import React from 'react';

function GoogleMap() {
  return (
    <iframe
      title="google map"
      width={500}
      height={475}
      src="https://maps.google.com/maps?q=羽匠空間設計&region=TW&hl=zh-TW&output=embed"
      frameBorder="0"
      scrolling="no"
      marginHeight={0}
      marginWidth={0}
    ></iframe>
  );
}

export default GoogleMap;
