import React from 'react';
import Pagination from './Pagination';
import Carousel from './Carousel';

// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

// type definition
import Case, { CaseI } from './Case';

// carousel images
import A from './images/carousel/mobile/1.webp';
import B from './images/carousel/mobile/2.webp';
import C from './images/carousel/mobile/3.webp';

import A1 from './images/carousel/desktop/1.webp';
import B1 from './images/carousel/desktop/2.webp';
import C1 from './images/carousel/desktop/3.webp';

// case data
import { CasesAry } from './CasesData';

const carouselImagesMobile = [A, B, C];
const carouselImagesDesktop = [A1, B1, C1];

const PAGE_SIZE = 8;
const TOTAL_SIZE = CasesAry.length;

function Cases(props: any) {
  let path: string = props.match.params.page ?? '1';
  let currentPage = parseInt(path, 10);

  if (isNaN(currentPage)) {
    currentPage = 1;
  }

  // check if the current page exceed the max number of pages
  if (currentPage > Math.ceil(TOTAL_SIZE / PAGE_SIZE)) {
    window.location.href = '/cases';
  }

  return (
    <>
      <Navbar />
      <div className="cases">
        <h1>作品案例｜PROJECT</h1>
        <CarouselWraapper showThumbs={false} />
        <ul>
          {CasesAry.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE).map(
            (c: CaseI, idx: number) => (
              <li key={`${c?.title}-${c?.location}`}>
                <Case showCase={c} />
              </li>
            )
          )}
        </ul>
        <Pagination pageSize={PAGE_SIZE} totalSize={TOTAL_SIZE} currentPage={currentPage - 1} />
      </div>
      <Footer />
    </>
  );
}

const CarouselWraapper = (props: any) => {
  return (
    <>
      <div className="mobile">
        <Carousel carouselImages={carouselImagesMobile} {...props} />
      </div>
      <div className="desktop">
        <Carousel carouselImages={carouselImagesDesktop} {...props} />
      </div>
    </>
  );
};

export default Cases;
