import { CaseI } from './Case';

// cases images
import case1Small1 from './images/case1/small1.webp';
import case1Small2 from './images/case1/small2.webp';
import case1bigger1 from './images/case1/bigger1.jpeg';
import case1bigger2 from './images/case1/bigger2.jpg';
import case1bigger3 from './images/case1/bigger3.jpg';
import case1bigger4 from './images/case1/bigger4.jpg';
import case1bigger5 from './images/case1/bigger5.jpg';
import case1bigger6 from './images/case1/bigger6.jpg';

import case2Small1 from './images/case2/small1.webp';
import case2Small2 from './images/case2/small2.webp';

import case3Small1 from './images/case3/small1.webp';
import case3Small2 from './images/case3/small2.webp';

import case4Small1 from './images/case4/small1.webp';
import case4Small2 from './images/case4/small2.webp';

import case5Small1 from './images/case5/small1.webp';
import case5Small2 from './images/case5/small2.webp';

import case2bigger1 from './images/case2/bigger1.webp';
import case2bigger2 from './images/case2/bigger2.webp';
import case2bigger3 from './images/case2/bigger3.webp';
import case2bigger4 from './images/case2/bigger4.webp';
import case2bigger5 from './images/case2/bigger5.webp';
import case2bigger6 from './images/case2/bigger6.webp';
import case2bigger7 from './images/case2/bigger7.webp';

import case3bigger1 from './images/case3/bigger1.webp';
import case3bigger2 from './images/case3/bigger2.webp';
import case3bigger3 from './images/case3/bigger3.webp';
import case3bigger4 from './images/case3/bigger4.webp';
import case3bigger5 from './images/case3/bigger5.webp';
import case3bigger6 from './images/case3/bigger6.webp';

import case4bigger1 from './images/case4/bigger1.webp';
import case4bigger2 from './images/case4/bigger2.webp';
import case4bigger3 from './images/case4/bigger3.webp';
import case4bigger4 from './images/case4/bigger4.webp';
import case4bigger5 from './images/case4/bigger5.webp';
import case4bigger6 from './images/case4/bigger6.webp';

import case5bigger1 from './images/case5/bigger1.webp';
import case5bigger2 from './images/case5/bigger2.webp';
import case5bigger3 from './images/case5/bigger3.webp';
import case5bigger4 from './images/case5/bigger4.webp';
import case5bigger5 from './images/case5/bigger5.webp';
import case5bigger6 from './images/case5/bigger6.webp';
import case5bigger7 from './images/case5/bigger7.webp';
import case5bigger8 from './images/case5/bigger8.webp';

import case6bigger1 from './images/case6/bigger1.webp';
import case6bigger2 from './images/case6/bigger2.webp';
import case6bigger3 from './images/case6/bigger3.webp';
import case6bigger4 from './images/case6/bigger4.webp';
import case6bigger5 from './images/case6/bigger5.webp';
import case6bigger6 from './images/case6/bigger6.webp';
import case6bigger7 from './images/case6/bigger7.webp';
import case6bigger8 from './images/case6/bigger8.webp';
import case6bigger9 from './images/case6/bigger9.webp';

import case7bigger1 from './images/case7/bigger1.webp';
import case7bigger2 from './images/case7/bigger2.webp';
import case7bigger3 from './images/case7/bigger3.webp';
import case7bigger4 from './images/case7/bigger4.webp';
import case7bigger5 from './images/case7/bigger5.webp';

import case8bigger1 from './images/case8/bigger1.webp';
import case8bigger2 from './images/case8/bigger2.webp';
import case8bigger3 from './images/case8/bigger3.webp';
import case8bigger4 from './images/case8/bigger4.webp';
import case8bigger5 from './images/case8/bigger5.webp';

import case9bigger1 from './images/case9/bigger1.webp';
import case9bigger2 from './images/case9/bigger2.webp';
import case9bigger3 from './images/case9/bigger3.webp';
import case9bigger4 from './images/case9/bigger4.webp';
import case9bigger6 from './images/case9/bigger6.webp';
import case9bigger5 from './images/case9/bigger5.webp';
import case9bigger7 from './images/case9/bigger7.webp';

import case10bigger1 from './images/case10/bigger1.webp';
import case10bigger2 from './images/case10/bigger2.webp';
import case10bigger3 from './images/case10/bigger3.webp';
import case10bigger4 from './images/case10/bigger4.webp';
import case10bigger5 from './images/case10/bigger5.webp';

import case11bigger1 from './images/case11/bigger1.webp';
import case11bigger2 from './images/case11/bigger2.webp';
import case11bigger3 from './images/case11/bigger3.webp';
import case11bigger4 from './images/case11/bigger4.webp';
import case11bigger5 from './images/case11/bigger5.webp';
import case11bigger6 from './images/case11/bigger6.webp';
import case11bigger7 from './images/case11/bigger7.webp';
import case11bigger8 from './images/case11/bigger8.webp';
import case11bigger9 from './images/case11/bigger9.webp';

import case12bigger1 from './images/case12/bigger1.webp';
import case12bigger2 from './images/case12/bigger2.webp';
import case12bigger3 from './images/case12/bigger3.webp';

import case13bigger1 from './images/case13/bigger1.webp';
import case13bigger2 from './images/case13/bigger2.webp';
import case13bigger3 from './images/case13/bigger3.webp';
import case13bigger4 from './images/case13/bigger4.webp';
import case13bigger5 from './images/case13/bigger5.webp';
import case13bigger6 from './images/case13/bigger6.webp';

import case14bigger1 from './images/case14/bigger1.webp';
import case14bigger2 from './images/case14/bigger2.webp';
import case14bigger3 from './images/case14/bigger3.webp';
import case14bigger4 from './images/case14/bigger4.webp';
import case14bigger5 from './images/case14/bigger5.webp';
import case14bigger6 from './images/case14/bigger6.webp';
import case14bigger7 from './images/case14/bigger7.webp';
import case14bigger8 from './images/case14/bigger8.webp';
import case14bigger10 from './images/case14/bigger10.webp';

import case15bigger1 from './images/case15/bigger1.webp';
import case15bigger2 from './images/case15/bigger2.webp';
import case15bigger3 from './images/case15/bigger3.webp';
import case15bigger4 from './images/case15/bigger4.webp';
import case15bigger5 from './images/case15/bigger5.webp';
import case15bigger6 from './images/case15/bigger6.webp';

import case16bigger1 from './images/case16/bigger1.webp';
import case16bigger2 from './images/case16/bigger2.webp';
import case16bigger3 from './images/case16/bigger3.webp';
import case16bigger4 from './images/case16/bigger4.webp';

import case17bigger1 from './images/case17/bigger1.webp';
import case17bigger2 from './images/case17/bigger2.webp';
import case17bigger3 from './images/case17/bigger3.webp';
import case17bigger4 from './images/case17/bigger4.webp';
import case17bigger5 from './images/case17/bigger5.webp';

import case18bigger1 from './images/case18/bigger1.webp';
import case18bigger2 from './images/case18/bigger2.webp';
import case18bigger3 from './images/case18/bigger3.webp';
import case18bigger4 from './images/case18/bigger4.webp';
import case18bigger5 from './images/case18/bigger5.webp';
import case18bigger6 from './images/case18/bigger6.webp';
import case18bigger7 from './images/case18/bigger7.webp';

import case19bigger1 from './images/case19/bigger1.webp';
import case19bigger2 from './images/case19/bigger2.webp';
import case19bigger3 from './images/case19/bigger3.webp';
import case19bigger4 from './images/case19/bigger4.webp';
import case19bigger5 from './images/case19/bigger5.webp';
import case19bigger6 from './images/case19/bigger6.webp';
import case19bigger7 from './images/case19/bigger7.webp';
import case19bigger8 from './images/case19/bigger8.webp';
import case19bigger9 from './images/case19/bigger9.webp';
import case19bigger10 from './images/case19/bigger10.webp';

import case20bigger1 from './images/case20/bigger1.webp';
import case20bigger2 from './images/case20/bigger2.webp';
import case20bigger3 from './images/case20/bigger3.webp';
import case20bigger4 from './images/case20/bigger4.webp';
import case20bigger5 from './images/case20/bigger5.webp';

import case21bigger1 from './images/case21/bigger1.webp';
import case21bigger2 from './images/case21/bigger2.webp';
import case21bigger3 from './images/case21/bigger3.webp';
import case21bigger4 from './images/case21/bigger4.webp';
import case21bigger5 from './images/case21/bigger5.webp';
import case21bigger7 from './images/case21/bigger7.webp';

import case22bigger1 from './images/case22/bigger1.webp';
import case22bigger2 from './images/case22/bigger2.webp';
import case22bigger3 from './images/case22/bigger3.webp';
import case22bigger4 from './images/case22/bigger4.webp';
import case22bigger5 from './images/case22/bigger5.webp';
import case22bigger6 from './images/case22/bigger6.webp';

import case23bigger1 from './images/case23/bigger1.webp';
import case23bigger2 from './images/case23/bigger2.webp';
import case23bigger3 from './images/case23/bigger3.webp';
import case23bigger4 from './images/case23/bigger4.webp';
import case23bigger5 from './images/case23/bigger5.webp';

import case24bigger1 from './images/case24/bigger1.webp';
import case24bigger2 from './images/case24/bigger2.webp';
import case24bigger3 from './images/case24/bigger3.webp';

import case25bigger1 from './images/case25/bigger1.webp';
import case25bigger3 from './images/case25/bigger3.webp';
import case25bigger4 from './images/case25/bigger4.webp';
import case25bigger5 from './images/case25/bigger5.webp';
import case25bigger6 from './images/case25/bigger6.webp';
import case25bigger7 from './images/case25/bigger7.webp';

import case26bigger1 from './images/case26/bigger1.webp';
import case26bigger3 from './images/case26/bigger3.webp';
import case26bigger4 from './images/case26/bigger4.webp';
import case26bigger5 from './images/case26/bigger5.webp';

import case27bigger1 from './images/case27/bigger1.jpg';
import case27bigger2 from './images/case27/bigger2.webp';
import case27bigger3 from './images/case27/bigger3.webp';
import case27bigger4 from './images/case27/bigger4.webp';
import case27bigger5 from './images/case27/bigger5.webp';
import case27bigger6 from './images/case27/bigger6.webp';

import case28bigger1 from './images/case28/bigger1.webp';
import case28bigger2 from './images/case28/bigger2.webp';
import case28bigger3 from './images/case28/bigger3.webp';
import case28bigger4 from './images/case28/bigger4.webp';
import case28bigger5 from './images/case28/bigger5.webp';

import case29bigger1 from './images/case29/bigger1.webp';
import case29bigger2 from './images/case29/bigger2.webp';
import case29bigger3 from './images/case29/bigger3.webp';
import case29bigger4 from './images/case29/bigger4.webp';
import case29bigger5 from './images/case29/bigger5.webp';
import case29bigger6 from './images/case29/bigger6.webp';

import case30bigger1 from './images/case30/bigger1.webp';
import case30bigger2 from './images/case30/bigger2.webp';
import case30bigger3 from './images/case30/bigger3.webp';
import case30bigger4 from './images/case30/bigger4.webp';
import case30bigger5 from './images/case30/bigger5.webp';

import case31bigger1 from './images/case31/bigger1.jpg';
import case31bigger2 from './images/case31/bigger2.jpg';
import case31bigger3 from './images/case31/bigger3.jpg';
import case31bigger4 from './images/case31/bigger4.jpg';

import case32bigger1 from './images/case32/bigger1.jpg';
import case32bigger2 from './images/case32/bigger2.jpg';
import case32bigger3 from './images/case32/bigger3.jpg';
import case32bigger4 from './images/case32/bigger4.jpg';
import case32bigger5 from './images/case32/bigger5.jpg';
import case32bigger6 from './images/case32/bigger6.jpg';

import case33bigger1 from './images/case33/bigger1.jpg';
import case33bigger2 from './images/case33/bigger2.jpg';
import case33bigger3 from './images/case33/bigger3.jpg';
import case33bigger4 from './images/case33/bigger4.jpg';
import case33bigger5 from './images/case33/bigger5.jpg';

import case34bigger1 from './images/case34/bigger1.jpg';
import case34bigger2 from './images/case34/bigger2.jpg';
import case34bigger3 from './images/case34/bigger3.jpg';
import case34bigger4 from './images/case34/bigger4.jpg';
import case34bigger5 from './images/case34/bigger5.jpg';
import case34bigger6 from './images/case34/bigger6.jpg';
import case34bigger7 from './images/case34/bigger7.jpg';

import case35bigger1 from './images/case35/bigger1.jpg';
import case35bigger2 from './images/case35/bigger2.jpg';
import case35bigger3 from './images/case35/bigger3.jpg';
import case35bigger4 from './images/case35/bigger4.jpg';

import case36bigger1 from './images/case36/bigger1.jpg';
import case36bigger2 from './images/case36/bigger2.jpg';
import case36bigger3 from './images/case36/bigger3.jpg';

import case37bigger1 from './images/case37/bigger1.jpg';
import case37bigger2 from './images/case37/bigger2.jpg';
import case37bigger3 from './images/case37/bigger3.jpg';

import case38bigger1 from './images/case38/bigger1.webp';
import case38bigger2 from './images/case38/bigger2.webp';
import case38bigger3 from './images/case38/bigger3.webp';
import case38bigger4 from './images/case38/bigger4.webp';

import case39bigger1 from './images/case39/bigger1.webp';
import case39bigger2 from './images/case39/bigger2.webp';
import case39bigger3 from './images/case39/bigger3.webp';
import case39bigger4 from './images/case39/bigger4.webp';
import case39bigger5 from './images/case39/bigger5.webp';

import case40bigger1 from './images/case40/bigger1.webp';
import case40bigger2 from './images/case40/bigger2.webp';
import case40bigger3 from './images/case40/bigger3.webp';

import case41bigger1 from './images/case41/bigger1.webp';
import case41bigger2 from './images/case41/bigger2.webp';
import case41bigger3 from './images/case41/bigger3.webp';

import case42bigger1 from './images/case42/bigger1.webp';
import case42bigger2 from './images/case42/bigger2.webp';
import case42bigger3 from './images/case42/bigger3.webp';
import case42bigger4 from './images/case42/bigger4.webp';

import case43bigger1 from './images/case43/bigger1.webp';
import case43bigger2 from './images/case43/bigger2.webp';
import case43bigger3 from './images/case43/bigger3.webp';

import case44bigger1 from './images/case44/bigger1.webp';
import case44bigger2 from './images/case44/bigger2.webp';
import case44bigger3 from './images/case44/bigger3.webp';
import case44bigger4 from './images/case44/bigger4.webp';

import case45bigger1 from './images/case45/bigger1.webp';
import case45bigger2 from './images/case45/bigger2.webp';
import case45bigger3 from './images/case45/bigger3.webp';
import case45bigger4 from './images/case45/bigger4.webp';

import case46bigger1 from './images/case46/bigger1.webp';
import case46bigger2 from './images/case46/bigger2.webp';
import case46bigger3 from './images/case46/bigger3.webp';
import case46bigger4 from './images/case46/bigger4.webp';
import case46bigger5 from './images/case46/bigger5.webp';

import case47bigger1 from './images/case47/bigger1.webp';
import case47bigger2 from './images/case47/bigger2.webp';
import case47bigger3 from './images/case47/bigger3.webp';
import case47bigger4 from './images/case47/bigger4.webp';
import case47bigger5 from './images/case47/bigger5.webp';
import case47bigger6 from './images/case47/bigger6.webp';

import case48bigger1 from './images/case48/bigger1.webp';
import case48bigger2 from './images/case48/bigger2.webp';
import case48bigger3 from './images/case48/bigger3.webp';
import case48bigger4 from './images/case48/bigger4.webp';

import case49bigger1 from './images/case49/bigger1.webp';
import case49bigger2 from './images/case49/bigger2.webp';
import case49bigger3 from './images/case49/bigger3.webp';

import case50bigger1 from './images/case50/bigger1.webp';
import case50bigger2 from './images/case50/bigger2.webp';

import case51bigger1 from './images/case51/bigger1.webp';
import case51bigger2 from './images/case51/bigger2.webp';
import case51bigger3 from './images/case51/bigger3.webp';

import case52bigger1 from './images/case52/bigger1.webp';
import case52bigger2 from './images/case52/bigger2.webp';

import case53bigger1 from './images/case53/bigger1.webp';
import case53bigger2 from './images/case53/bigger2.webp';
import case53bigger3 from './images/case53/bigger3.webp';

import case54bigger1 from './images/case54/bigger1.webp';
import case54bigger2 from './images/case54/bigger2.webp';
import case54bigger3 from './images/case54/bigger3.webp';

export const CasesAry: Array<CaseI> = [
  // 1-10
  {
    title: '深紅汕頭鍋物 S.H Shantou Hotpot',
    location: '台北民權店',
    description:
      '座落於台北市中心，從起初的斑駁，直至現在的截然不同，整面玻璃磚牆微微透出晶瑩剔透的內在，讓人忍不住想往裡面一探究竟',
    tags: ['臺北市', '大型餐飲空間', '室內兩層'],
    imgs: {
      small: [case1Small1, case1Small2],
      bigger: [case1bigger1, case1bigger2, case1bigger3, case1bigger4, case1bigger5, case1bigger6],
    },
  },
  {
    title: 'Old rattan coffee 荖藤咖啡',
    location: '台南旗艦店',
    description:
      '坐落於街角明顯的位置，有一間大面積落地玻璃窗搭配木質感的環境採光以及明亮度配上寬敞的座位，創造空間和諧，豐富且不壅擠，體驗不同的視覺感受',
    tags: ['臺南市', '咖啡輕食空間'],
    imgs: {
      small: [case2Small1, case2Small2],
      bigger: [
        case2bigger1,
        case2bigger2,
        case2bigger3,
        case2bigger4,
        case2bigger5,
        case2bigger6,
        case2bigger7,
      ],
    },
  },
  {
    title: '穩飲茶府',
    location: '竹南總府',
    description: '在流行潮流中獨樹一幟，融合復古傳統，點亮店面的經典魅力。',
    tags: ['苗栗縣', '飲品品牌'],
    imgs: {
      small: [case41bigger3, case41bigger1],
      bigger: [case41bigger1, case41bigger2, case41bigger3],
    },
  },
  {
    title: '深紅汕頭鍋物 S.H Shantou Hotpot',
    location: '臺北SOGO敦化店',
    description:
      '每一個細節都經過精心雕琢，打造出典雅而獨特的店面氛圍，為店面帶來璀璨的光彩和令人驚艷的視覺享受。',
    tags: ['臺北市', '餐飲品牌', '大型餐飲空間', '百貨裝潢'],
    imgs: {
      small: [case42bigger1, case42bigger2],
      bigger: [case42bigger1, case42bigger2, case42bigger3, case42bigger4],
    },
  },
  {
    title: '佬饕私鍋',
    location: '高雄岡山店',
    description:
      '步入復古老宅，在懷舊風格中體驗舌尖的熱情，將火鍋文化與傳統風味完美融合，享受古早味道的珍貴時光。',
    tags: ['高雄市', '餐飲品牌', '室內兩層'],
    imgs: {
      small: [case43bigger1, case43bigger3],
      bigger: [case43bigger1, case43bigger2, case43bigger3],
    },
  },
  {
    title: '植作茶',
    location: '新北新店店',
    description: '以自然為靈感，營造舒適愜意的空間，品味飲品的同時感受大自然的親近與寧靜。',
    tags: ['新北市', '飲品品牌'],
    imgs: {
      small: [case44bigger2, case44bigger3],
      bigger: [case44bigger1, case44bigger2, case44bigger3, case44bigger4],
    },
  },
  {
    title: '喥咕采耳 𝗦𝗹𝗲𝗲𝗽𝘆 𝗘𝗮𝗿',
    location: ' ',
    description:
      '營造溫暖舒適的空間氛圍，沉浸在柔和的色調和細膩的質感中，專業護理服務的同時感受到自然的愛與呵護。',
    tags: ['臺中市', '美容美體', '室內兩層'],
    imgs: {
      small: [case45bigger2, case45bigger3],
      bigger: [case45bigger1, case45bigger2, case45bigger3, case45bigger4],
    },
  },
  {
    title: '新岡土虱',
    location: ' ',
    description:
      '猶如書法般的墨痕，傳統書法的藝術之美融入餐飲空間，帶來品味與雅致，在美食中感受文學與美感的共舞。',
    tags: ['臺中市', '餐飲品牌', '品牌設計'],
    imgs: {
      small: [case46bigger2, case46bigger1],
      bigger: [case46bigger1, case46bigger2, case46bigger3, case46bigger4, case46bigger5],
    },
  },
  {
    title: '翠林雅境',
    location: ' ',
    description:
      '金屬材質的奢華質感為居室增添了高雅品味，而自然木紋元素則賦予了空間舒適與自然的氛圍。這種完美的結合，盡情展現個人品味，感受到尊榮與奢華的融合，讓居住成為一種享受，一種真正的高級生活體驗。',
    tags: ['高雄市', '住宅設計', '室內兩層'],
    imgs: {
      small: [case47bigger2, case47bigger1],
      bigger: [
        case47bigger1,
        case47bigger2,
        case47bigger3,
        case47bigger4,
        case47bigger5,
        case47bigger6,
      ],
    },
  },
  {
    title: '一分錢一分貨moneycake車輪餅',
    location: ' ',
    description: '融合清新木紋元素，打造出清爽自然的車輪餅店面空間，同時感受大自然的愜意氛圍。',
    tags: ['臺北市', '點心品牌'],
    imgs: {
      small: [case48bigger2, case48bigger1],
      bigger: [case48bigger1, case48bigger2, case48bigger3, case48bigger4],
    },
  },
  {
    title: 'N.V pizza 黑.火山披薩',
    location: ' 臺中太平店',
    description:
      '走向美式工業風格的披薩外帶店面，營造出充滿潮流魅力的獨特空間，享受美式披薩的美味與風格。',
    tags: ['臺中市', '餐飲品牌', '外帶店'],
    imgs: {
      small: [case49bigger2, case49bigger1],
      bigger: [case49bigger1, case49bigger2, case49bigger3],
    },
  },
  {
    title: '易百分肉蛋吐司早餐',
    location: '臺南善化站前店',
    description:
      '木紋元素的細膩紋理與純白色的清潔感相得益彰，營造出一個簡約而溫馨的空間，帶來寧靜與舒適的氛圍。',
    tags: ['臺南市', '餐飲品牌'],
    imgs: {
      small: [case50bigger1, case50bigger2],
      bigger: [case50bigger1, case50bigger2],
    },
  },
  {
    title: '士官長野地餐廳',
    location: '  ',
    description:
      '裝潢走傳統輕復古風格，，散發著藝術氛圍和歷史的厚重感，讓您在空間享受美食中沉浸在悠久的文化底蘊。',
    tags: ['臺中市', '餐飲品牌'],
    imgs: {
      small: [case51bigger3, case51bigger2],
      bigger: [case51bigger1, case51bigger2, case51bigger3],
    },
  },
  {
    title: '植作茶',
    location: '雲林虎尾店',
    description: '淺木紋的溫暖質感，帶來自然之美，在清新的環境中品味細膩的飲品滋味。',
    tags: ['雲林縣', '飲品品牌'],
    imgs: {
      small: [case52bigger1, case52bigger2],
      bigger: [case52bigger1, case52bigger2],
    },
  },
  {
    title: '六貓茶坊',
    location: '龍井總店',
    description: '大面積的鵝黃色與精緻的貓手繪圖案相互輝映，營造出獨特而吸引同樣貓奴的飲品天地。',
    tags: ['臺中市', '飲品品牌'],
    imgs: {
      small: [case53bigger1, case53bigger3],
      bigger: [case53bigger1, case53bigger2, case53bigger3],
    },
  },
  {
    title: 'N.V pizza 黑.火山披薩',
    location: '臺中大里店',
    description:
      '店面裝潢充滿著時尚前衛的設計，營造出一種現代感十足的魅力，讓您的外帶體驗更加別具一格。',
    tags: ['臺中市', '餐飲品牌', '外帶店'],
    imgs: {
      small: [case54bigger1, case54bigger3],
      bigger: [case54bigger1, case54bigger2, case54bigger3],
    },
  },
  {
    title: '漫步藍咖啡Ramble Cafe',
    location: '霧峰門市',
    description: '清新的大面積白色漆面搭配沉穩的藍色做點綴，簡單大氣！',
    tags: ['臺中市', '咖啡輕食空間'],
    imgs: {
      small: [case3Small1, case3Small2],
      bigger: [case3bigger1, case3bigger2, case3bigger3, case3bigger4, case3bigger5, case3bigger6],
    },
  },
  {
    title: '不要對我尖叫',
    location: '新北淡水店',
    description:
      '繁華的淡水街道中，各種顏色的招牌參差不齊，其中一個亮白色招牌就是最醒目的。白色為主體色的空間，如同純白的畫紙，點綴、揮灑不同的顏色後，將會呈現最搶眼的畫面。',
    tags: ['新北市', '飲品品牌'],
    imgs: {
      small: [case4Small1, case4Small2],
      bigger: [case4bigger1, case4bigger2, case4bigger3, case4bigger4, case4bigger5, case4bigger6],
    },
  },
  {
    title: '深紅汕頭鍋物 S.H Shantou Hotpot',
    location: '竹北店',
    description:
      '門面以藍色磁磚帶出柔嫩的空間卻又不過分甜膩，輕鬆地讓空間呈現優雅與浪漫的清新氛圍。',
    tags: ['新竹縣', '大型餐飲空間'],
    imgs: {
      small: [case5Small1, case5Small2],
      bigger: [
        case5bigger1,
        case5bigger2,
        case5bigger3,
        case5bigger4,
        case5bigger5,
        case5bigger6,
        case5bigger7,
        case5bigger8,
      ],
    },
  },
  {
    title: 'YO COFFEE 悠咖啡',
    location: '天母店',
    description: '以品牌形象，依照不同的店面型式做搭配，表達品牌主要視覺。',
    tags: ['臺北市', '咖啡輕食空間'],
    imgs: {
      small: [case6bigger5, case6bigger3],
      bigger: [
        case6bigger1,
        case6bigger2,
        case6bigger3,
        case6bigger4,
        case6bigger5,
        case6bigger6,
        case6bigger7,
        case6bigger8,
        case6bigger9,
      ],
    },
  },
  {
    title: '植作茶',
    location: '桃園龍潭店',
    description:
      '設計是一種感受、一種態度、一種舒適感、一種開心的生活方式用心的去體會，將會擁有不同的生活品味',
    tags: ['桃園市', '飲品品牌'],
    imgs: {
      small: [case7bigger4, case7bigger2],
      bigger: [case7bigger1, case7bigger2, case7bigger3, case7bigger4, case7bigger5],
    },
  },
  {
    title: 'Ramble café 漫步藍咖啡',
    location: '金山店',
    description: '漫步在金山，找到嚮往的藍。走獅頭山會看到燭台嶼…沉靜在咖啡廳裡尋找自己的顏色',
    tags: ['新北市', '咖啡餐飲輕食空間'],
    imgs: {
      small: [case8bigger1, case8bigger3],
      bigger: [case8bigger1, case8bigger2, case8bigger3, case8bigger4, case8bigger5],
    },
  },
  {
    title: '深紅汕頭鍋物 S.H Shantou Hotpot',
    location: '北屯崇德店',
    description: '「深紅的紅不是顏色的紅，是對客人持之以恆熱情的紅」',
    tags: ['臺中市', '大型餐飲空間'],
    imgs: {
      small: [case9bigger1, case9bigger3],
      bigger: [
        case9bigger1,
        case9bigger2,
        case9bigger3,
        case9bigger4,
        case9bigger6,
        case9bigger5,
        case9bigger7,
      ],
    },
  },
  {
    title: 'YO COFFEE 悠咖啡',
    location: '南投埔里店',
    description:
      '陽光灑入咖啡廳，暖光與原木線條交織出和諧與悠然，這一切都要感謝埔里的好山好水，與土地之間的連結又更親近了呢',
    tags: ['南投縣', '咖啡輕食空間'],
    imgs: {
      small: [case10bigger1, case10bigger2],
      bigger: [case10bigger1, case10bigger2, case10bigger3, case10bigger4, case10bigger5],
    },
  },
  // 11-20
  {
    title: '深紅汕頭鍋物 S.H Shantou Hotpot',
    location: '台中美村店',
    description:
      '當舞動的燈光與空間交織的那一刻，你我都不敢相信燈光的營造是如此的高深與美妙，在這樣的氛圍下體驗美食，是何等享受呢',
    tags: ['台中市', '大型餐飲空間'],
    imgs: {
      small: [case11bigger1, case11bigger6],
      bigger: [
        case11bigger1,
        case11bigger2,
        case11bigger3,
        case11bigger4,
        case11bigger5,
        case11bigger6,
        case11bigger7,
        case11bigger8,
        case11bigger9,
      ],
    },
  },
  {
    title: '植作茶',
    location: '鳳山店',
    description:
      '木頭是自然界最常被運用於空間設計的建材之一，擁有略粗糙而溫潤的觸感色彩變化豐富的特性',
    tags: ['高雄市', '飲品品牌'],
    imgs: {
      small: [case12bigger1, case12bigger3],
      bigger: [case12bigger1, case12bigger2, case12bigger3],
    },
  },
  {
    title: '漫步藍咖啡Ramble Cafe',
    location: '復興門市',
    description: '與車廠結合之咖啡廳，精工磨細節，用心顧品質',
    tags: ['臺中市', '咖啡輕食空間'],
    imgs: {
      small: [case13bigger1, case13bigger2],
      bigger: [
        case13bigger1,
        case13bigger2,
        case13bigger3,
        case13bigger4,
        case13bigger5,
        case13bigger6,
      ],
    },
  },
  {
    title: '新銳咖啡 Sensory cafe - 深夜咖啡 第一品牌',
    location: '五權美術館店',
    description: '跳拖不同一般的設計風格，搭配大面積鐵件元素，質感與空間感並肩',
    tags: ['臺中市', '咖啡輕食空間'],
    imgs: {
      small: [case14bigger1, case14bigger4],
      bigger: [
        case14bigger1,
        case14bigger2,
        case14bigger3,
        case14bigger4,
        case14bigger5,
        case14bigger6,
        case14bigger7,
        case14bigger8,
        case14bigger10,
      ],
    },
  },
  {
    title: 'YO COFFEE 悠咖啡',
    location: '中壢瓏昌店    ',
    description: '雙門面店面設計再搭配透明玻璃窗，以穿透式手法，可以讓整體視野更為通暢遼闊',
    tags: ['桃園市', '咖啡輕食空間'],
    imgs: {
      small: [case15bigger1, case15bigger2],
      bigger: [
        case15bigger1,
        case15bigger2,
        case15bigger3,
        case15bigger4,
        case15bigger5,
        case15bigger6,
      ],
    },
  },
  {
    title: '植作茶',
    location: '台南店',
    description: '臺南市古色古香，飲料店不計其數，需保有品牌特色才能獨樹一幟。',
    tags: ['臺南市', '飲品品牌'],
    imgs: {
      small: [case16bigger1, case16bigger4],
      bigger: [case16bigger1, case16bigger2, case16bigger3, case16bigger4],
    },
  },
  {
    title: '植作茶',
    location: '高雄前鎮店',
    description:
      '以稻草人為品牌代言人，象徵守護消費者的健康與對品質的把關。空間既不偏離品牌軸心，也有屬於自己的主題故事。',
    tags: ['高雄市', '飲品品牌'],
    imgs: {
      small: [case17bigger1, case17bigger2],
      bigger: [case17bigger1, case17bigger2, case17bigger3, case17bigger4, case17bigger5],
    },
  },
  {
    title: 'iMetta Juice · 愛莓噠果汁',
    location: '臺中市',
    description:
      '打破一般傳統果汁攤位的印象，採用黑色系與木質風格元素，簡單不複雜的線條設計，悄悄融合在空間中',
    tags: ['臺中市', '飲品品牌'],
    imgs: {
      small: [case18bigger3, case18bigger2],
      bigger: [
        case18bigger1,
        case18bigger2,
        case18bigger3,
        case18bigger4,
        case18bigger5,
        case18bigger6,
        case18bigger7,
      ],
    },
  },
  {
    title: '新銳咖啡 Sensory cafe - 深夜咖啡 第一品牌​',
    location: '五權民族店',
    description: '大片落地窗和前區座位上方引進自然採光，讓整間咖啡館顯得相當明亮',
    tags: ['臺中市', '咖啡輕食空間'],
    imgs: {
      small: [case19bigger1, case19bigger2],
      bigger: [
        case19bigger1,
        case19bigger2,
        case19bigger3,
        case19bigger4,
        case19bigger5,
        case19bigger6,
        case19bigger7,
        case19bigger8,
        case19bigger9,
        case19bigger10,
      ],
    },
  },
  {
    title: '目木原覺',
    location: '臺中市',
    description:
      '介於黑白之間的灰色調，擁有能百搭的自然特性，也可以透過材質的變化運用，藉以調出空間的框架，並提升層次感',
    tags: ['臺中市', '飲品品牌'],
    imgs: {
      small: [case20bigger2, case20bigger3],
      bigger: [case20bigger1, case20bigger2, case20bigger3, case20bigger4, case20bigger5],
    },
  },
  // 21-30
  {
    title: '兔子兔子早午餐 Rabbit Rabbit Café',
    location: '中和大仁店',
    description:
      '商標作為空間設計靈感源， 透過像是材質、色彩的表現，或是將圖像輸出方式並以相框或大圖呈現，所衍生出的空間設計，希望能讓停留的顧客印象深刻',
    tags: ['新北市', '餐飲品牌'],
    imgs: {
      small: [case21bigger2, case21bigger3],
      bigger: [
        case21bigger1,
        case21bigger2,
        case21bigger3,
        case21bigger4,
        case21bigger5,
        case21bigger7,
      ],
    },
  },
  {
    title: '新銳咖啡Sensory Cafe',
    location: '微風松高店',
    description:
      '一間以咖啡生活為主題特色咖啡館。天花板垂掛著鴿子，象徵著自由，椅子以咖啡杯外型製成沙發，呈現一種與咖啡融合的感受',
    tags: ['臺北市', '咖啡餐飲品牌'],
    imgs: {
      small: [case22bigger1, case22bigger6],
      bigger: [
        case22bigger1,
        case22bigger2,
        case22bigger3,
        case22bigger4,
        case22bigger5,
        case22bigger6,
      ],
    },
  },
  {
    title: '植作茶',
    location: '林口店',
    description:
      '巷弄中，豎立著水藍色清新的門面，帶點文青又簡約的風格，成為當街最引人注目的飲料品牌',
    tags: ['桃園市', '飲品品牌'],
    imgs: {
      small: [case23bigger1, case23bigger2],
      bigger: [case23bigger1, case23bigger2, case23bigger3, case23bigger4, case23bigger5],
    },
  },
  {
    title: '十二韻',
    location: '太平中山店',
    description: '清新淡雅的木作色調佐簡約俐落的展示區域，運用白色及大地綠，營造整體視覺感',
    tags: ['臺中市', '飲品品牌'],
    imgs: {
      small: [case24bigger1, case24bigger2],
      bigger: [case24bigger1, case24bigger2, case24bigger3],
    },
  },
  {
    title: 'YO COFFEE 悠咖啡',
    location: '岡山忠誠店',
    description: '坪數較大的咖啡館，在店面氛圍營造方面，燈光也是不可或缺的重要主角。',
    tags: ['高雄市', '咖啡輕食空間'],
    imgs: {
      small: [case25bigger5, case25bigger7],
      bigger: [
        case25bigger1,
        case25bigger3,
        case25bigger4,
        case25bigger5,
        case25bigger6,
        case25bigger7,
      ],
    },
  },
  {
    title: '植作茶',
    location: '香港店',
    description: '利用植物作為裝飾點綴，並以暖黃色調的燈光營造出溫馨感。',
    tags: ['香港', '飲品品牌'],
    imgs: {
      small: [case26bigger1, case26bigger3],
      bigger: [case26bigger1, case26bigger3, case26bigger4, case26bigger5],
    },
  },
  {
    title: 'Umi親子廚房',
    location: '海洋館',
    description:
      '主題式餐廳的族群為親子，需搭配較為鮮豔亮麗的色彩來吸引小朋友的目光，一同悠遊在海洋世界中。 ',
    tags: ['臺中市', '飲品品牌'],
    imgs: {
      small: [case27bigger1, case27bigger2],
      bigger: [
        case27bigger1,
        case27bigger2,
        case27bigger3,
        case27bigger4,
        case27bigger5,
        case27bigger6,
      ],
    },
  },
  {
    title: '兔子兔子早午餐 Rabbit Rabbit Café',
    location: '台南赤崁樓店',
    description: '位於臺南市鬧區。利用大圖形象設計，配合品牌整體空間，希望呈現出品牌的視覺風格',
    tags: ['臺南市', '咖啡輕食品牌'],
    imgs: {
      small: [case28bigger1, case28bigger2],
      bigger: [case28bigger1, case28bigger2, case28bigger3, case28bigger4, case28bigger5],
    },
  },
  {
    title: 'pizza-factory披薩工廠',
    location: '興大店',
    description:
      '跳色系亮出專屬的空間氛圍！搭配工業風格，加入不同的元素、搭配相襯的材質，也能讓空間變得豐富。',
    tags: ['臺中市', '餐飲品牌'],
    imgs: {
      small: [case29bigger2, case29bigger3],
      bigger: [
        case29bigger1,
        case29bigger2,
        case29bigger3,
        case29bigger4,
        case29bigger5,
        case29bigger6,
      ],
    },
  },
  {
    title: 'YO COFFEE 悠咖啡',
    location: '溪東店',
    description: '店門大面的落地窗讓店內更明亮，呈現商業空間美學的風格。',
    tags: ['高雄市', '咖啡輕食空間'],
    imgs: {
      small: [case30bigger2, case30bigger5],
      bigger: [case30bigger1, case30bigger2, case30bigger3, case30bigger4, case30bigger5],
    },
  },
  // 31-40
  {
    title: '十二韻',
    location: '菲律賓店',
    description: '將極小空間做出最極致化利用，品牌色調搭配木質紋理提升溫潤質感',
    tags: ['菲律賓', '飲品品牌'],
    imgs: {
      small: [case31bigger1, case31bigger2],
      bigger: [case31bigger1, case31bigger2, case31bigger3, case31bigger4],
    },
  },
  {
    title: '新銳咖啡 Sensory cafe',
    location: '臺北天母店',
    description: '木紋與黑白相互搭配崁上光色較亮的燈光，照映開放式空間，使整體更加凸顯、耀眼',
    tags: ['新北市', '咖啡輕食品牌'],
    imgs: {
      small: [case32bigger1, case32bigger2],
      bigger: [
        case32bigger1,
        case32bigger2,
        case32bigger3,
        case32bigger4,
        case32bigger5,
        case32bigger6,
      ],
    },
  },
  {
    title: '新銳咖啡 Sensory cafe',
    location: '板橋麗寶店',
    description:
      '百貨大樓中心的咖啡館，搭配不同色系、異材質的使用，同時不破壞原有的和諧感，營造悠閒空間',
    tags: ['新北市', '咖啡輕食品牌'],
    imgs: {
      small: [case33bigger1, case33bigger2],
      bigger: [case33bigger1, case33bigger2, case33bigger3, case33bigger4, case33bigger5],
    },
  },
  {
    title: 'YO COFFEE 悠咖啡',
    location: '河南店',
    description: '純白與木質堆砌出清新明亮感，簡單耐看樸實有味道',
    tags: ['臺中市', '咖啡輕食品牌'],
    imgs: {
      small: [case34bigger1, case34bigger2],
      bigger: [
        case34bigger1,
        case34bigger2,
        case34bigger3,
        case34bigger4,
        case34bigger5,
        case34bigger6,
        case34bigger7,
      ],
    },
  },
  {
    title: 'YO COFFEE 悠咖啡',
    location: '宜蘭羅東店',
    description: '暖色系給人帶來溫暖柔和的感覺，能使人心情舒暢',
    tags: ['宜蘭縣', '咖啡輕食品牌'],
    imgs: {
      small: [case35bigger1, case35bigger2],
      bigger: [case35bigger1, case35bigger2, case35bigger3, case35bigger4],
    },
  },
  {
    title: '十二韻',
    location: '大雅清泉店',
    description: '主打清新木質色調為主，烘托樸實親切的氛',
    tags: ['臺中市', '飲品品牌'],
    imgs: {
      small: [case36bigger1, case36bigger2],
      bigger: [case36bigger1, case36bigger2, case36bigger3],
    },
  },
  {
    title: '不要對我尖叫',
    location: '台北內湖瑞光店',
    description: '以淺灰色大理石紋搭配金屬檯面，創造出不同以往的視覺感受。',
    tags: ['臺北市', '飲品品牌'],
    imgs: {
      small: [case37bigger1, case37bigger2],
      bigger: [case37bigger1, case37bigger2, case37bigger3],
    },
  },
  {
    title: '不要對我尖叫',
    location: '基隆廟口店',
    description:
      '不需要華麗的裝飾，不必要過多的色彩，簡單中又能賦予空間個性，這不就是生活中所追求的簡單幸福嗎',
    tags: ['基隆', '飲品品牌'],
    imgs: {
      small: [case38bigger1, case38bigger2],
      bigger: [case38bigger1, case38bigger2, case38bigger3, case38bigger4],
    },
  },
  {
    title: '家食Tea',
    location: '臺中烏日店',
    description:
      '復古與現代的衝突，讓現場觀賞的人們擁有全新的視覺體驗，場景的變化，彷彿來到了優雅又繽紛的巴塞隆納，與眾不同的世界，將呈現在你我的眼前',
    tags: ['臺中市', '飲品複合式空間'],
    imgs: {
      small: [case39bigger1, case39bigger2],
      bigger: [case39bigger1, case39bigger2, case39bigger3, case39bigger4, case39bigger5],
    },
  },
  {
    title: '啵肥',
    location: '臺北士林店',
    description:
      '身處在士林夜市，周圍是琳瑯滿目的商店，白綠色的六角磚點綴櫃台，六角形木紋美耐板裝飾牆壁，讓小小的空間，增添了一些亮點',
    tags: ['臺北市', '鬆餅複合式品牌'],
    imgs: {
      small: [case40bigger1, case40bigger2],
      bigger: [case40bigger1, case40bigger2, case40bigger3],
    },
  },
];
