import React from 'react';
import { Link } from 'react-router-dom';

// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Carousel from './Carousel';

// case dta
import { CasesAry } from './CasesData';

function CaseDetail(props: any) {
  /*
    use idx to get the page => it's not good because someone may change the order,
    and make page content change (bad for SEO)
  
    batter to use alias the go to the route (but need to filter the array every time)
  
	const id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
	const showCaseNum: number = Number(id);

  if (!Number.isInteger(showCaseNum) || showCaseNum >= CasesAry.length || showCaseNum < 0) {
		window.location.href = '/cases';
		return;
  }
  */
  const page = props.match.params.page;
  const dashIdx = page.lastIndexOf('-');
  const foundTitle = page.slice(0, dashIdx);
  const foundLocation = page.slice(dashIdx + 1);
  const showCase = CasesAry.find((e) => e.title === foundTitle && e.location === foundLocation);

  if (!showCase) {
    return null;
  }

  const {
    title,
    location,
    description,
    imgs: { bigger },
  } = showCase;

  return (
    <>
      <Navbar />
      <div className="case-detail">
        <h1>作品案例｜PROJECT</h1>
        <div className="content">
          <Carousel showThumbs={true} carouselImages={bigger} />
          <div className="detail-section">
            <div className="title">
              {title} - {location}
            </div>
            <div className="line"></div>
            <div className="description">{description}</div>
            <Link to="/cases" className="learn-more">
              Learn More
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CaseDetail;
