import React from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';

interface PaginationPropsI {
  pageSize: number;
  totalSize: number;
  currentPage: number;
}

function Pagination({ pageSize, totalSize, currentPage }: PaginationPropsI) {
  const totalPage = Math.ceil(totalSize / pageSize);
  const history = useHistory();

  const handlePageClick = ({ selected }: { selected: number }) => {
    history.push(`/cases/${selected + 1}`);
  };

  return (
    <div className="pagination">
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        forcePage={currentPage}
        pageCount={totalPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={'pagination-component'}
        activeClassName={'active'}
      />
    </div>
  );
}

export default Pagination;
