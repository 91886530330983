import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import logo from './logo.webp';

type NavItem = {
  ZH: string;
  EN: string;
  url: string;
};

const links: Array<NavItem> = [
  {
    ZH: '關於羽匠',
    EN: 'About',
    url: '/about',
  },
  {
    ZH: '作品案例',
    EN: 'Work',
    url: '/cases',
  },
  {
    ZH: '服務內容',
    EN: 'Service',
    url: '/service',
  },
  {
    ZH: '客戶回饋',
    EN: 'User like us',
    url: '/user-like-us',
  },
  {
    ZH: '常見問題',
    EN: 'Most asked questions',
    url: '/faq',
  },
  {
    ZH: '聯絡洽詢',
    EN: 'Contact',
    url: '/contact',
  },
];

const Navbar = () => {
  const [isMenuActive, setMenuActive] = useState(false);
  const labelClass = cx({
    'is-active': isMenuActive,
  });

  return (
    <header>
      <div className="header-content">
        <div className="content">
          <div>
            <label
              htmlFor="toggler"
              id="main-menu-toggle"
              className="menu-toggle"
              aria-label="Open main menu"
            >
              <div
                className={`hamburger--slider ${labelClass}`}
                onClick={() => setMenuActive((v) => !v)}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </div>
            </label>
            <Link to="/">
              <img src={logo} alt="" className="logo" />
            </Link>
          </div>
          <input type="checkbox" id="toggler" name="toggle" className="" />
          <nav id="main-menu" className="main-menu" aria-label="Main menu">
            <ul>
              {links.map((o) => (
                <li key={o.ZH}>
                  <a href={o.url}>{o.ZH}</a>
                </li>
              ))}
            </ul>
          </nav>
          <label htmlFor="toggler" className="backdrop"></label>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
