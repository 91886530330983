import React, { useRef, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// css
import './Footer.scss';

// imgs
import facebook from './FB-01.png';
import instagram from './IG-01.png';
import line from './LINE-01.png';
import youtube from './YOUTUBE-01.png';

interface formClass {
  nameClass: string;
  phoneClass: string;
  emailClass: string;
  messageClass: string;
}

const SocialMediaBlock = () => {
  return (
    <div className="social-media">
      <a href="https://lin.ee/RGMsanu">
        <img src={line} alt="line" />
      </a>
      <a href="https://www.facebook.com/YuJames28626">
        <img src={facebook} alt="facebook" />
      </a>
      <a href="https://www.instagram.com/invites/contact/?i=1owbjca26tfeq&utm_content=6n7l8z5">
        <img src={instagram} alt="instagram" />
      </a>
      <a href="https://www.youtube.com/channel/UCuVRFyMnyyccBmxGhdKJFvQ">
        <img src={youtube} alt="youtube" />
      </a>
    </div>
  );
};

function Footer() {
  const [sendingStatus, setSendingStatus] = useState('pending');

  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  let formEleClass: formClass = {
    nameClass: '',
    phoneClass: '',
    emailClass: '',
    messageClass: '',
  };

  function handleContact(e: any) {
    e.preventDefault();

    if (!nameRef.current || !phoneRef.current || !emailRef.current || !messageRef.current) {
      return;
    }

    formEleClass = {
      nameClass: nameRef.current.value ? '' : 'error',
      phoneClass: phoneRef.current.value ? '' : 'error',
      emailClass: emailRef.current.value ? '' : 'error',
      messageClass: messageRef.current.value ? '' : 'error',
    };

    const isValid = Object.entries(formEleClass).every(([_, value]) => {
      return value !== 'error';
    });

    if (!isValid) {
      return;
    }

    const csrf = uuidv4();

    document.cookie = `csrf=${csrf};`;

    setSendingStatus('sending');

    axios
      .post('/.netlify/functions/sendemail', {
        name: nameRef.current.value,
        phone: phoneRef.current.value,
        email: emailRef.current.value,
        message: messageRef.current.value,
        csrf: csrf,
      })
      .then((res) => {
        setSendingStatus('success');
      })
      .catch(() => {
        setSendingStatus('error');
      });
  }

  return (
    <div className="footer">
      {/* contact info */}
      <div className="contact-info">
        <div className="header">
          <div className="title">聯絡洽詢 | Location</div>
          <SocialMediaBlock />
        </div>
        <ul className="information">
          {/* company information */}
          <li className="company-information">
            <div className="subtitle">公司資訊</div>
            <ul>
              <li>
                <div className="label">聯絡電話:</div>
                <div className="text">04-22913131</div>
              </li>
              <li>
                <div className="label">統&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;編:</div>
                <div className="text">85000446</div>
              </li>
              <li>
                <div className="label">LINE&nbsp;&nbsp;&nbsp;ID:</div>
                <div className="text">@yujames</div>
              </li>
              <li>
                <div className="label">信&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱:</div>
                <div className="text">yujames28626@gmail.com</div>
              </li>
              <li>
                <div className="label">公司地址:</div>
                <div className="text">臺中市北區陝西東五街80號1樓</div>
              </li>
            </ul>
          </li>
          {/* links */}
          <li className="links">
            <div className="subtitle">快速連結</div>
            <ul>
              <li>
                <a href="/about">關於羽匠</a>
                <a href="/user-like-us">客戶回饋</a>
              </li>
              <li>
                <a href="/cases">作品案例</a>
                <a href="/faq">常見問題</a>
              </li>
              <li>
                <a href="/service">服務內容</a>
                <a href="/contact">聯絡洽詢</a>
              </li>
            </ul>
          </li>
          {/* your information */}
          <li className="your-information">
            <div className="subtitle">歡迎留下您的聯繫方式</div>
            <form onSubmit={handleContact}>
              <ul>
                <li>
                  <input
                    placeholder="姓名 your name"
                    ref={nameRef}
                    className={formEleClass.nameClass}
                    maxLength={100}
                  />
                </li>
                <li>
                  <input
                    placeholder="電話 phone"
                    type="tel"
                    ref={phoneRef}
                    className={formEleClass.phoneClass}
                    maxLength={50}
                  />
                </li>
                <li>
                  <input
                    placeholder="信箱 e-mail"
                    type="email"
                    ref={emailRef}
                    className={formEleClass.emailClass}
                    maxLength={100}
                  />
                </li>
              </ul>
              <textarea
                placeholder="訊息 message"
                ref={messageRef}
                className={formEleClass.messageClass}
                maxLength={400}
              ></textarea>
              <button>
                {sendingStatus === 'sending'
                  ? '寄出中'
                  : sendingStatus === 'error'
                  ? '寄出失敗'
                  : sendingStatus === 'success'
                  ? '寄出成功'
                  : 'send'}
              </button>
            </form>
            <SocialMediaBlock />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
