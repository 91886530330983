import React, { Fragment } from 'react';
// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

// images
import Showcase1 from './images/1.jpg';
import Showcase2 from './images/2.jpg';
import Showcase3 from './images/3.jpg';
import Showcase4 from './images/4.jpg';
import Showcase5 from './images/5.jpg';
import Showcase6 from './images/6.jpg';
import Showcase7 from './images/7.jpg';
import Showcase8 from './images/8.jpg';
import Showcase9 from './images/9.jpg';
import Showcase10 from './images/10.jpg';
import Showcase11 from './images/11.jpg';
import Showcase12 from './images/12.jpg';


interface ImageCase {
  image: string;
}

interface WordCase {
  time: string;
  feedbacks: string;
  name: string;
}

type Case = ImageCase | WordCase;

const cases: Array<Case> = [
  {
    time: 'Jun 07,2023',
    feedbacks:
      '我們的店面經過重新設計後，客流量增加了，有賣得比之前好耶！謝謝~',
    name: '飲品品牌 許小姐',
  },
  {
    image: Showcase1,
  },
  {
    time: 'Aug 10,2022',
    feedbacks:
      '整個裝修過程非常順利，我們家現在變得更漂亮了。',
    name: '張公館 張太太',
  },
  {
    image: Showcase2,
  },
  {
    time: 'Feb 14, 2022',
    feedbacks: '真的很感謝團隊非常用心，很有耐心和我們說明工法，工程按時完工。',
    name: '西屯公館 呂小姐',
  },
  {
    time: 'MAY 10,2022',
    feedbacks: '裝潢的時候，他們一直保持工地整潔，這種細節讓我印象深刻。',
    name: '早午餐品牌 陳先生',
  },
  {
    time: 'DEC 18,2022',
    feedbacks: '整個裝修過程中，他們不斷與我們溝通，讓我們了解進展',
    name: '飲品品牌 黃小姐',
  },
  {
    time: 'NOV 21,2022',
    feedbacks: '想要趕在過年前完成，還好有提早開始',
    name: '餐飲品牌 John',
  },
  {
    image: Showcase3,
  },
  {
    image: Showcase4,
  },
   {
    time: 'MAY 02,2022',
    feedbacks: '合作過程中很輕鬆，彷彿只要把難題丟出去，下次開會就會得到建設性的建議。',
    name: '餐飲連鎖品牌 Aggie ',
  },
   {
    time: 'Sep 22,2022',
    feedbacks: '從討論規劃到完工都盡心盡力，裝潢材料品質也不偷工減料！',
    name: '餐飲品牌 陳小姐',
  },
  {
    time: 'Nov 27,2021',
    feedbacks: '近期因有內部裝潢設計的需求，進而與羽匠空間設計接洽，並詢問合作相關流程及設計風格等問題，過程窗口及內部設計師皆十分有耐心的聽完需求，爾後給予明確的建議和方向，讓人感受安心和專業的一間公司！',
    name: '餐廳 王先生',
  },
  {
    time: 'JUL 27,2021',
    feedbacks: '從討論、設計、規劃、預算的拿捏、施工品質都很用心在每一個環節上。',
    name: '咖啡廳 王小姐',
  },
  {
    time: 'MAR 14,2019',
    feedbacks: '之前配合的統包根本都亂做，分店這次配合做的很好',
    name: '餐飲品牌 鄭小姐',
  },
  {
    time: 'DEC 26,2018',
    feedbacks: '服務態度很棒，有任何問題都會即時處理',
    name: '飲料品牌 黃小姐',
  },
  {
    image: Showcase5,
  },
  {
    time: 'AUG 19,2019',
    feedbacks: '幫我們順利完成店面，非常專業，感謝設計師的用心。',
    name: '咖啡廳 林先生',
  },
  {
    time: 'APR 29,2018',
    feedbacks: '會依照需求給予建議！服務細心周到，值得推薦',
    name: '飲品品牌 王小姐',
  },
  {
    time: 'SEP 10,2019',
    feedbacks: '透過朋友介紹的，對於工班的施工品質也不馬虎，非常專業',
    name: '餐飲品牌 徐小姐',
  },
  {
    image: Showcase6,
  },
  {
    time: 'JUN 05,2019',
    feedbacks: '討論過程很愉快，整個團隊都很親切',
    name: '咖啡廳 陳先生',
  },
  {
    image: Showcase7,
  },
  {
    time: 'DEC 21,2019',
    feedbacks: '站在使用者角度思考。遇到不懂的地方也會耐心的回答，整體設計讓到公司的人都覺得很讚。',
    name: '飲品品牌 王小姐',
  },
  {
    image: Showcase8,
  },
  {
    time: 'SEP 26,2019',
    feedbacks: '裝潢完也有一些問題需要資訊，團隊都還是第一時間幫我處理，除了遇到好的設計團隊，更重要的是後續的服務態度',
    name: '餐飲品牌 吳先生',
  },
  {
    time: 'JUL 03,2019',
    feedbacks: '很用心的去了解客戶需求，施工細節上也有注意到，很實在的一間設計裝潢公司',
    name: '徐公館 徐先生',
  },
  {
    time: 'APR 14,2021',
    feedbacks: '公司團隊年輕，設計更能貼近年輕人需求，且利用line群組溝通，自己的想法都能很快得到回應，很不錯。',
    name: '咖啡廳品牌 林小姐',
  },
  {
    image: Showcase9,
  },
  {
    time: 'MAR 30,2020',
    feedbacks: '短時間能清楚聆聽預算、需求、並給予專業且符合內心想規劃的設計，很值得推薦都設計團隊！',
    name: '飲品品牌 蘇先生',
  },
  {
    image: Showcase10,
  },
  {
    time: 'JUN 06,2021',
    feedbacks: '無論是丈量或設計圖討論過程中都很細心，也能給予專業且符合需求的建議。',
    name: '鄭公館 鄭小姐',
  },
  {
    image: Showcase11,
  },
  {
    time: 'JUN 06,2021',
    feedbacks: '服務態度一直以來很不錯，推薦！',
    name: '餐飲品牌 劉先生',
  },
  {
    image: Showcase12,
  },
  {
    time: 'JUN 06,2019',
    feedbacks: '與這間公司合作過幾次，成果都還不錯，也都會主動打電話來關心',
    name: '飲品品牌 蔡小姐',
  },
   {
    time: 'Feb 19,2021',
    feedbacks:
      '真的很感謝團隊非常用心，很多小細節都有注意到，也會和我們先溝通，有朋友需要一定先推薦你們',
    name: '陳公館 陳小姐',
  },
  {
    time: 'MAY 10,2019',
    feedbacks: '設計師和藹可親的態度和專業的建議 讓我很放心直接交託',
    name: '楊公館 楊小姐',
  },
  {
    time: 'DEC 18,2019',
    feedbacks: '店面簽約租金就開始算錢了，還好畫圖設計很快，減少我的成本消耗',
    name: '飲品品牌 鄭先生',
  },
   {
    time: 'Oct 07,2020',
    feedbacks:
      '以前偶爾聽朋友講設計蟑螂，原先也會擔心，因此找了許多家設計公司比較過，最後找到一家專業度較高的設計公司，真的非常感謝你們的用心。',
    name: '咖啡廳 王小姐',
  },
];

function Card({ children, className }: any) {
  return <li className={`card ${className}`}>{children}</li>;
}

function index() {
  return (
    <Fragment>
      <Navbar />
      <div className="user-like-us">
        <div className="scroll-block">
          <h1>客戶的回饋是我們前進的良藥｜REVIEWS</h1>
          <div className="scroll">scroll</div>
          <div className="arrow">
            <svg
              preserveAspectRatio="xMidYMid meet"
              data-bbox="4.832 21.464 190.802 42.443"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="4.832 21.464 190.802 42.443"
              role="presentation"
              aria-hidden="true"
            >
              <g>
                <path d="M192.05 39H42V24.161c0-2.073-2.057-3.369-3.853-2.333L6.156 40.353c-1.796 1.037-1.749 3.628.047 4.665l31.922 18.524C39.919 64.579 42 63.283 42 61.21V46h150.05a3.501 3.501 0 1 0 0-7z"></path>
              </g>
            </svg>
          </div>
        </div>
        {/* show case */}
        <ul className="cases">
          {cases.map((c: Case) => {
            if ('image' in c) {
              return (
                <Card className="image-type">
                  <img src={c.image} alt="" />
                </Card>
              );
            } else {
              return (
                <Card className="word-type">
                  <div className="word-block">
                    <div className="time">{c.time}</div>
                    <div className="feedback">{c.feedbacks}</div>
                    <div className="name">{c.name}</div>
                  </div>
                </Card>
              );
            }
          })}
        </ul>
      </div>
      <Footer />
    </Fragment>
  );
}

export default index;
