import React from 'react';
// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

// images
import section1Mobile from './images/section1-mobile.jpg';
import section1Desktop from './images/section1-desktop.webp';

interface FAQ {
  question: string;
  answer: string;
}

const questions: Array<FAQ> = [
  {
    question: `Q1.請問服務的地區包含哪裡?`,
    answer: `國內外、北中南皆有承攬，並且不需要因為換地區而重新找過設計統包廠商。`,
  },
  {
    question: `Q2.請問有哪些服務項目?`,
    answer: `您好，我們公司提供<br/>
    1. 商業空間規畫設計及工程承攬<br/>
    2. 辦公空間規畫設計及工程承攬<br/>
    3. 住宅內外規畫設計及工程承攬<br/>
    4. 其他空間規畫設計及工程承攬<br/>
    5. 品牌規畫`,
  },
  {
    question: 'Q3.如何將自己喜歡的風格以及需求清晰表示給予協助之設計人員?',
    answer: `設計風格依各國民俗風情及時代背景有所不同，會衍生出諸多不同的型態，建議客戶們能先準備好自己喜歡的空間風格照片或資料，提出自己的需求以及想法，另外也可以提出自己的預算，以利設計人員能夠實現客戶腦海中的藍圖。`,
  },
  {
    question: `Q4. 該如何選擇設計公司呢?`,
    answer: `不惑於名氣或是公司規模，應該透過溝通及觀察其作品，因名氣可以透過廣告、網路的行銷放送，塑造出一個專業的形象，而如透過室內設計公司官網的作品集，實際的見面聊聊天，方可找出符合自己的設計公司。`,
  },
  {
    question: `Q5.裝潢工程交給統包公司好還是自行發包?`,
    answer: `裝修工程牽涉的工種多達數十種，甚至更多，工程中工法可能一直更新進步，而這類相關資訊，則是需要經由專業單位進修，並非依靠網路資料就能夠精通，且資料無法求證適用與否。然而工程多數工班只做一種工程，有時並非統包公司，時常進修並懂得相關工種的結合。`,
  },
  {
    question: `Q6.請問施工完成後如果有問題可以再找你們嗎?`,
    answer: `我們公司工程完成驗收後，如後續有碰到問題，可與我們聯繫討論，無須擔心找不到人為您解答疑惑。`,
  },
  {
    question: `Q7.我們和其他裝修公司有哪邊不同?`,
    answer: `長期於該產業的我們，不只累積了專業知識經驗，更累積了許多長久合作的夥伴，並且窗口單一。`,
  },
  {
    question: `Q8. 與設計單位初步接洽時，該了解哪些細節呢?`,
    answer: `我們整理了諸多客戶反饋並擔心的幾個要點：<br/>
      1.廠商裝修經驗不足，無法替客戶有效客製化及客觀的建議 <br/>
      2.態度負面，無法良善解決各項問題<br/>
      3.設計的方向，不符合客戶使用需求<br/>
      4.窗口過多，需自行找師傅溝通<br/>
      5.報價不合理
      `,
  },
  {
    question: `Q9.裝潢需要多久的施工時間呢?`,
    answer: `施工時間的長短需視工程的規模大小而有所不同，若為商業空間、住宅空間，則視空間大小與工程規模而定，也牽涉到是否有租約問題、坪數大小、住宅有人居住等問題，短則幾週長則需要幾個月的工程時間。因此，在討論階段時，會跟業主確認每項工程進場的時間，以及完工交屋的日期。`,
  },
  {
    question: `Q10.顏色上不知如何選擇該怎麼辦?`,
    answer: `「配色」是每個設計基礎中相當重要的因素之一，這也是很多客戶遇到的問題，偏偏色彩對一個空間來說佔視覺的百分之九十。每個空間對客戶來說都是獨一無二，當有色彩配色問題時，設計單位將依不同的風格以及空間樣式提供多種建議。`,
  },
];

function Index() {
  return (
    <div className="faq-page">
      <Navbar />
      {/* first section */}
      <section className="first-section">
        <div className="image-wrapper">
          <img src={section1Mobile} alt="show case" className="mobile" />
          <img src={section1Desktop} alt="show case" className="desktop" />
        </div>
      </section>
      <section className="second-section">
        <h1>常見問題｜PROBLEMS</h1>
        <ul>
          {questions.map(({ question: q, answer: a }, idx) => {
            return (
              <li key={q}>
                <input type="checkbox" id={q} />
                <label htmlFor={q}>
                  {q} <i className="arrow"></i>{' '}
                </label>
                <p dangerouslySetInnerHTML={{ __html: a }}></p>
              </li>
            );
          })}
        </ul>
      </section>
      <Footer />
    </div>
  );
}

export default Index;
