import React from 'react';
// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

// images
import section1Mobile from './section1-mobile.jpg';
import section1Desktop from './section1-desktop.webp';

import showCase1 from './show-case1.webp';
import showCase2 from './show-case2.webp';
import showCase3 from './show-case3.webp';
import showCase4 from './show-case4.webp';

function about() {
  return (
    <div className="about-page">
      <Navbar />
      {/* first section */}
      <section className="first-section">
        <h1>關於羽匠｜ABOUT</h1>
        <div className="image-wrapper">
          <img src={section1Mobile} alt="show case" className="mobile" />
          <img src={section1Desktop} alt="show case" className="desktop" />
        </div>
        <div className="text">
          <div className="title">「匠藝之至，匠心不熄。」</div>
          <br />
          <p>
            Yu James
            羽匠空間設計公司坐立於台灣臺中市。重視溝通以及美感空間，提供客戶專業的空間設計規劃、住宅設計、辦公空間、平面設計、品牌設計…等，並且不論北中南、國外，皆有所服務之客戶。
          </p>
          <p>
            設計不只於創造美感，是用來「解決問題、創造價值」，它關係到空間、效益、人及永續，我們堅持整合空間與機能的平衡性，提供相關的經驗與專業，將理想落實。
          </p>
          <br />
          <p>
            服務近八年，已為許多咖啡廳、餐飲企業…等打造各具風格的裝潢，更能夠站在客戶的角度著想，如何利用設計創造「價值極大化」，也提供穩定的售後服務。
          </p>
        </div>
      </section>
      <section className="second-section grid">
        <div className="wrapper img1">
          <img src={showCase1} alt="" />
        </div>
        <div className="text area1">
          <h2>No.1 整合</h2>
          <p>空間設計及工程整合、統包，不需額外找設計師或是找工班</p>
        </div>
        <div className="wrapper img2">
          <img src={showCase2} alt="" />
        </div>
        <div className="text area2">
          <h2>NO.2 品質</h2>
          <p>提供客製化服務，用心專注細節，力求品質。</p>
        </div>
        <div className="wrapper img3">
          <img src={showCase3} alt="" />
        </div>
        <div className="text area3">
          <h2>NO.3 聯繫窗口單一</h2>
          <p>聯繫窗口單一，不需聯繫多個廠商，提升溝通效率。</p>
        </div>
        <div className="wrapper img4">
          <img src={showCase4} alt="" />
        </div>
        <div className="text area4">
          <h2>NO.4 全區域皆可提供服務</h2>
          <p>國內外、北中南皆有承攬，並且不需要因為換地區而重新找過設計統包廠商</p>
        </div>
      </section>
      <section className="third-section">
        <h2>經營理念</h2>
        <p>
          只有用心付出，方能獲得更多信任，並期望與客戶在互信互賴的基礎上，
          <br /> 共同成長，永續發展。
        </p>
        <p>一、以充滿責任心的態度去完成客戶需求。</p>
        <p>二、客製化的設計完成客戶腦海中的藍圖。</p>
        <p>三、力求品質，不辜負任何一絲信賴。</p>
      </section>
      <section className="fourth-section">
        <h2>
          設計，一切的起點，貼近需求，便是設計的目的。
          <br />
          工程，讓設計呈現於你我眼前，實踐您心中藍圖。
        </h2>
        <a href="/cases">作品案例</a>
      </section>
      <Footer />
    </div>
  );
}

export default about;
