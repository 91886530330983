import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import routeConfig, { routesMap } from './configs/routeConfig';

type routeType = Array<{
  path: string;
  component: string;
  name: string;
}>;

const getRoute = (routeConfig: routeType) => {
  return routeConfig.map((r, idx) => {
    const Handler = routesMap[r.component];
    if (Handler) {
      return <Route path={r.path} component={Handler} key={idx} />;
    }
    return null;
  });
};

function Routes() {
  const Routes = getRoute(routeConfig);

  return (
    <Router>
      <Switch>{Routes}</Switch>
    </Router>
  );
}

export default Routes;
