import React, { Fragment } from 'react';
// components
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

// imagees
import service1 from './images/service1.jpg';
import service2 from './images/service2.jpg';
import service3 from './images/service3.jpg';
import banner from './images/banner.webp';

interface ServiceI {
  img: string;
  title: string;
  content: string;
}

const services: Array<ServiceI> = [
  {
    img: service1,
    title: '空間規畫設計',
    content: `
      商業 空間規畫設計<br/>
      住宅 空間規畫設計<br>
      展場 空間規畫設計<br>
      辦公 空間規畫設計<br>
      景觀 環境規畫設計<br>
      工廠 空間規畫設計<br>
      基地環境檢視、丈量<br>
    `,
  },
  {
    img: service2,
    title: '工程統包規畫',
    content: `
      商業 空間工程<br>
      住宅 空間工程<br>
      展場 空間工程<br>
      辦公 空間工程<br>
      景觀 環境工程<br>
    `,
  },
  {
    img: service3,
    title: '企業、品牌規畫設計',
    content: `
      企業、品牌視覺定位轉換<br>
      企業、品牌商標CI設計<br>
      視覺規範 - 色彩計畫<br>
      整體企業品牌規範制定<br>
      品牌市場定位<br>
      行銷經驗策略分享<br>
    `,
  },
];

interface ServiceDetailI {
  title: string;
  list: Array<string>;
}

interface ServiceDetailsI {
  title: string;
  services: Array<ServiceDetailI>;
}

const serviceDetails: Array<ServiceDetailsI> = [
  {
    title: '空間規畫設計流程',
    services: [
      {
        title: '​開案準備',
        list: ['洽談討論', '場地、評估丈量'],
      },
      {
        title: '​設計規畫',
        list: [
          '平面圖配置',
          '​設計合約簽約',
          '深度設計會議討論',
          '平面圖、建模圖繪製',
          '偕同客戶設計討論、修改',
        ],
      },
      {
        title: '結案',
        list: ['交付完整圖面', '結案'],
      },
    ],
  },
  {
    title: '工程統包規畫流程',
    services: [
      {
        title: '​開案準備',
        list: ['洽談討論', '工程報價'],
      },
      {
        title: '​設計規畫',
        list: ['​工程合約簽約', '深度工程會議討論', '施工人員安排規畫', '工程施工進行'],
      },
      {
        title: '結案',
        list: ['完工驗收', '售後服務'],
      },
    ],
  },
  {
    title: '企業、品牌規畫設計流程',
    services: [
      {
        title: '​開案準備',
        list: ['洽談討論', '企業、品牌規畫報價', '企業、品牌規畫設計簽約'],
      },
      {
        title: '​設計規畫',
        list: [
          '客戶深度洽談',
          '建立設計核心概念',
          'ＬＯＧＯ視覺規畫設計',
          '品牌視覺色彩應用',
          '企業、品牌規範制定',
          '偕同客戶設計討論、修改',
        ],
      },
      {
        title: '結案',
        list: ['交付完成品', '結案'],
      },
    ],
  },
];

const Arrow = ({ idx }: { idx: number }) => {
  return (
    <a className="arrow-wrapper" href={`#service-detail-id-${idx}`}>
      <p>進一步了解</p>
      <svg
        preserveAspectRatio="none"
        data-bbox="91 94 18 12"
        viewBox="91 94 18 12"
        xmlns="http://www.w3.org/2000/svg"
        data-type="shape"
        role="presentation"
        aria-hidden="true"
      >
        <g>
          <path d="M91 99h14.2l-3.6-3.6L103 94l6 6-6 6-1.4-1.4 3.6-3.6H91v-2z"></path>
        </g>
      </svg>
    </a>
  );
};

const Service = ({ img, title, content, idx }: ServiceI & { idx: number }) => {
  return (
    <li className="service-list-item">
      <div className="image-wrapper">
        <img src={img} alt={title} />
      </div>

      <h2>{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <Arrow idx={idx} />
    </li>
  );
};

const ServiceListItem = ({ desc }: { desc: string }) => {
  return <li className="desc">{desc}</li>;
};

const ServiceDetail = ({ services }: { services: Array<ServiceDetailI> }) => {
  return (
    <div className="service-detail">
      {services.map(({ title, list }) => {
        return (
          <div className="detail-content" style={{ flexGrow: list.length }}>
            <div className="sub-title">{title}</div>
            <ul>
              {list.map((desc) => (
                <ServiceListItem desc={desc} />
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

function Services() {
  return (
    <Fragment>
      <Navbar />
      <div className="service-page">
        <h1>服務內容｜SERVICES</h1>
        <ul className="service-section">
          {services.map((service, idx) => (
            <Service {...service} idx={idx} />
          ))}
        </ul>
        <div className="service-detail-section">
          <div className="image-wrapper">
            <img src={banner} alt="show case" />
          </div>
          <div>
            {serviceDetails.map(({ title, services }, idx) => (
              <div className="service-section">
                <div className="title" id={`service-detail-id-${idx}`}>
                  {title}
                </div>
                <ServiceDetail services={services} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Services;
