import React from 'react';
import { Link } from 'react-router-dom';

export interface CaseI {
  title: string;
  location: string;
  tags: string[];
  description?: string;
  imgs: {
    small: string[]; // for show case only
    bigger: string[]; // when users click to navigate, show bigger image
  };
}

function Case({ showCase }: { showCase: CaseI }) {
  const {
    title,
    location,
    tags,
    imgs: { small },
  } = showCase;

  const link = `/case-detail/${title}-${location}`;
  return (
    <div className="show-case">
      <Link to={link}>
        <div className="image-set">
          {small.slice(0, 2).map((url, idx) => (
            <img src={url} alt={title} key={`${title}-${location}-${idx}`} />
          ))}
        </div>
      </Link>
      <div className="title">
        <Link to={link}>{title}</Link>
      </div>
      <div className="location">
        <Link to={link}>&nbsp;– {location}</Link>
      </div>
      <ul className="tags">
        {tags.map((des) => (
          <li key={des}>{des}</li>
        ))}
      </ul>
    </div>
  );
}

export default Case;
